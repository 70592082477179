import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import HTMLHead from "../components/HTMLHead"
import { titleEl, contentEl } from "../styles/components/Page.module.css"

const Page = ({
  data: {
    wpPage: { title, content },
  },
}) => {
  return (
    <>
      <Helmet
        bodyAttributes={{
          class: "default-page",
        }}
      />
      <Layout>
        <h1 className={titleEl}>{title}</h1>
        <article className={contentEl} dangerouslySetInnerHTML={{ __html: content }}></article>
      </Layout>
    </>
  )
}

export const query = graphql`
  query ($slug: String!) {
    wpPage(slug: { eq: $slug }) {
      title
      content
    }
  }
`

export default Page

export const Head = ({
  data: {
    wpPage: { title },
  },
}) => <HTMLHead pageTitle={title} />
